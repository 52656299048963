.social-media {
  background-color: $secondary-color;
  border: 2px solid #000;
  border-radius: 20px 0 0 20px;
  border-right: none;
  position: fixed;
  right: 0;
  text-shadow: 2px 2px 4px #000;
  top: 290px;
  z-index: 10;

  ul {
    padding: 0;
    margin-bottom: 3px;
  }

  .social-icon {
    list-style: none;
    padding: 0 10px 0px 10px;

    a svg {
      display: block;
      fill:#000;
      // filter: drop-shadow(2px 2px 1px #000);
      height: 60px;
      margin: auto;
      padding: 10px 0;
      width: 40px;
    }
  }
}